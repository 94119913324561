.header {
    background-color: #000000 !important;
    position: fixed !important;
    z-index: 100;
    width: 100%;
    padding: 0 !important
}

.navbar-brand {
    /* font-family: "Georgia", "Times New Roman", Times, serif; */
    font-family: Verdana, "Geneva", sans-serif;
    font-size: 30px !important;
    color: #ffffff !important;
    margin-left: 20px;
}

/* .logoImage {
    height: 100px;
    width: 150px;
} */

.nav-item {
    /* position: absolute; */
    /* background-color: #212121; */
    /* top: 12px; */
    font-size: 18px;
    font-family: "Georgia", "Times New Roman", Times, serif;
    text-align: center;
    /* padding: 20px 0px; */
    width: 900px;
    /* margin: auto; */
    /* border-style: solid;
    border-width: 1px;
    border-color: #cccccc; */
}

.nav-link {
    color: #ffffff !important;
    font-size: 15px !important;
    /* padding: 0px 20px !important; */
    /* background-color: #212121; */
    width: 125px;
}

.fa {
    margin-top: 5px !important;
    font-size: 25px !important;
}

.nav-link:hover {
    color: #777777 !important;
}

.nav-link:active {
    border-bottom: 1px solid #ffffff !important;
}

.navbar-toggler {
    margin-right: 15px !important;
}
