.pageFooter {
    background: #000000 !important;
    text-align: center;
    font-size: 14px;
    padding: 10px 0px;
    height: 100%;
    position: relative;
    bottom: 0;
}

.text-white {
    color: #ffffff !important;
}

.push {
    height: 50px;
}
