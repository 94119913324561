.landingSection {
    /* background-color: #efeee7; */
    /* background: #eeeeee; */
    margin-top: 50px !important;
    /* background-image: url('../../assets/images/Homepage_292422020.jpg'); */
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */
}

h1 {
    font-family: 'Courgette', cursive, sans-serif;
    color: #4dacde;
    margin: 0;
    text-align: center;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* font-size: 28px; */
}

p {
    font-family: Verdana, "Geneva", sans-serif;
    line-height: 100%;
    color: #ffffff;
    margin: 0 !important;
    text-align: center;
}

hr {
    border: none;
    height: 5px;
    background-color:#dddddd;
    margin-top: 0;
    margin-bottom: 0;
}

.box01 {
    /* padding: 20px; */
    background: rgba(0, 0, 0, 0.5) !important;
    /* margin-bottom: 100px; */
    text-align: center;
}

.box02 {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5) !important;
    margin-bottom: 10px;
}

.infoLine {
    font-family: 'Courgette', cursive, sans-serif;
    color: #4dacde;
    margin: 0;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    /* font-size: 28px; */
}

.boldHeading {
    color: #ed4795;
}

.logoImage {
    width: 100%;
}

/* These rules take effect when the browser's width */
/* is either 225px or more. */
@media screen and (min-width: 225px) {
    .landingSection {
        margin-top: 100px;
        margin-bottom: 150px !important;
    }
    h1 {
        font-size: 20px !important;
    }
    p {
        font-size: 12px;
    }
}
/* These rules take effect when the browser's width */
/* is either 320px or more. */
@media screen and (min-width: 320px) {
}
/* These rules take effect when the browser's width */
/* is either 576px or more. */
@media screen and (min-width: 576px) {
    .landingSection {
        margin-top: 200px;
        margin-bottom: 300px !important;
    }
    p {
        font-size: 14px;
    }
}
/* These rules take effect when the browser's width */
/* is either 768px or more. */
@media screen and (min-width: 768px) {
    h1 {
        font-size: 25px !important;
    }
}
/* These rules take effect when the browser's width */
/* is either 992px or more. */
@media screen and (min-width: 992px) {
    h1 {
        font-size: 35px !important;
    }
    p {
        font-size: 18px;
    }
}
/* Extra small (xs) devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */

/* Small (sm) devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { ... } */

/* Medium (md) devices (tablets, 768px and up) */
/* @media (min-width: 768px) { ... } */

/* Large (lg) devices (desktops, 992px and up) */
/* @media (min-width: 992px) { ... } */

/* Extra (xl) large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { ... } */
